import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: window.localStorage.getItem('token') ? window.localStorage.getItem('token') : '',

    },
    mutations: {

        setToken: (state, token) => {
            state.token = token
                // window.localStorage.token = token
        },

    },
    actions: {
        setUserToken({ commit }, params) {
            commit('setToken', params)

        }
    },

    getters: {
        getToken: (state) => {
            return state.token
        }
    },
    modules: {

    }
})