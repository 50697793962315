import Axios from "axios";
// import store from 'vuex'
import qs from "qs";
import router from "../router/index";
import store from "../store/index";
import Vue from "vue";
var Vm = new Vue();
let hostName = window.location.hostname;
let isJumpLogin = false;
const request = Axios.create({
  // 正式环境
  baseURL:
    hostName == "web.niuniushouka.com"
      ? "https://api.niuniushouka.com"
      : "https://testapi.niuniushouka.com",
  // baseURL: "https://testapi.niuniushouka.com",
  timeout: 30000, // 请求超时
  headers: {},
});

// 添加请求拦截器
request.interceptors.request.use(
  function(config) {
    console.log(config);
    if (config.method == "get") {
      if (
        config.url == "/api/user/getInfo" && config.params
          ? config.params.isJumpLogin
          : false
      ) {
        isJumpLogin = config.params.isJumpLogin;
      }
    }
    // 在发送请求之前做些什么

    // 在发送请求之前做些什么
    // console.log(config)
    // console.log(store.state.token)

    if (store.state.token) {
      // 让每个请求携带令牌
      // ['X-Token']  自定义头键
      // 请根据实际情况修改
      config.headers["token"] = store.state.token;
    }
    config.headers["Content-Type"] =
      "application/x-www-form-urlencoded; charset=UTF-8";
    config.transformRequest = [
      function(data) {
        console.log("===", data);
        // 在请求之前对data传参进行格式转换
        data = qs.stringify(data);

        return data;
      },
    ];
    return config;
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  function(response) {
    // 对响应数据做点什么
    let data = response.data;
    if (data.code == "996" || data.code == "995") {
      if (isJumpLogin) {
      } else {
        window.localStorage.setItem("token", "");
        Vm.$toast({
          type: "fail",
          message: "请先登录",
        });
        router.push({
          path: "/Login",
        });
      }
    }
    return data;
  },
  function(error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default request;
