import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/Login",
    name: "",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/",
    name: "user",
    component: () => import("../views/User/index.vue"),
    redirect: "/cardView",
    children: [
      {
        path: "/cardView",
        name: "cardView",
        component: () => import("../views/User/cardView.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "/home",
        name: "home",
        component: () => import("../views/User/home.vue"),
        meta: {
          title: "牛牛收卡",
        },
      },
      {
        path: "/sellCardRecord",
        name: "sellCardRecord",
        component: () => import("../views/User/sellCardRecord.vue"),
        meta: {
          title: "售卡记录",
        },
      },
      {
        path: "/transferRecord",
        name: "transferRecord",
        component: () => import("../views/User/transferRecord.vue"),
        meta: {
          title: "提现记录",
        },
      },
      {
        path: "/personalCenter",
        name: "personalCenter",
        component: () => import("../views/User/personalCenter.vue"),
        meta: {
          title: "个人中心",
        },
      },
      {
        path: "/changeUserinfo",
        name: "changeUserinfo",
        component: () => import("../views/User/changeUserinfo.vue"),
        meta: {
          title: "信息修改",
        },
      },
      {
        path: "/realName",
        name: "realName",
        component: () => import("../views/realName/indexv2.vue"),
        meta: {
          title: "实名认证",
        },
      },
      {
        path: "/bindPhone",
        name: "bindPhone",
        component: () => import("../views/bindPhone/index.vue"),
        meta: {
          title: "绑定手机号",
        },
      },
      {
        path: "/withdrawal",
        name: "withdrawal",
        component: () => import("../views/User/withdrawal.vue"),
        meta: {
          title: "快速提现",
        },
      },
      {
        path: "/remitRecord",
        name: "remitRecord",
        component: () => import("../views/User/remitRecord.vue"),
        meta: {
          title:
            "打款记录                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ",
        },
      },
      {
        path: "/niuniuAgreement",
        name: "niuniuAgreement",
        component: () => import("../views/User/niuniuagreement.vue"),
        meta: {
          title: "牛牛收卡",
        },
      },
      {
        path: "/CommonProblem",
        name: "CommonProblem",
        component: () => import("../views/User/CommonProblem.vue"),
        meta: {
          title: "牛牛收卡",
        },
      },
      {
        path: "/announcement",
        name: "announcement",
        component: () => import("../views/announcement/index.vue"),
        meta: {
          title: "牛牛收卡",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");

    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}
router.beforeEach((to, from, next) => {
  next();
  // console.log(from, to, window.localStorage.getItem('token'))
  //   if (to.path == "/Login") {
  if (window.localStorage.getItem("token")) {
    // 已登录
    console.log("已登录");
    next();
    return false;
  } else {
    let token = getQueryVariable("token");
    if (token) {
      store.state.token = token;
      window.localStorage.setItem("token", token);
      console.log(store);
      next();
      return false;
    }
    next();
    return false;
  }
  // }
  //   if (!window.localStorage.getItem("token")) {
  //     console.log("未登陆");
  //     next({
  //       path: "/",
  //     });
  //     return false;
  //   }
  //   next();
  //   // if (window.localStorage.getItem('token')) {
  //   //     if (to.path == '/') {
  //   //         next({
  //   //             path: '/user'
  //   //         })
  //   //     } else {
  //   //         next()
  //   //     }
  //   // } else {
  //   //     next({
  //   //         path: '/'
  //   //     })
  //   // }
});

export default router;
