import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "lib-flexible";
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
import request from "./request/request";
Vue.prototype.$axios = request;
Vue.config.productionTip = false;
function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
}
let partner_id = getQueryString("partner_id");
let pcUrl = "http://www.niuniushouka.com/?";
if (partner_id) {
  window.localStorage.setItem("partner_id", partner_id);
  pcUrl = `${pcUrl}partner_id=${partner_id}`;
}
if (window.navigator.userAgent === "APP") {
} else if (
  window.navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
) {
  console.log("移动");
} else {
  console.log("pc");
  window.location = pcUrl;
  throw "切换pc端";
}
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
